// import $ from 'jquery';
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';
import flexibility from 'flexibility';
import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import isMobile from './modules/common/is-mobile.js';
import './modules/common/smoothScroll.js';
import './modules/common/lazyload.js';
import './modules/common/userAgent.js';
import './modules/common/sp-menu.js';
import './modules/common/scrollPageTop.js';
import './modules/common/tablefix.js';
import './modules/top/hero.js';
import './modules/top/animation.js';
import './modules/top/scrollpane.js';

flexibility(document.documentElement);//flexboxのie対応
objectFitImages();//object-fitのie対応
svg4everybody();//svgをieで表示


//電話番号スマホのみ掛ける
$("a[href*='tel']").on("click", function(e){
  if(!isMobile(768)){
    return false
  }
});

// 機能
// $("#PAGETOP").load("common/fx-pagetop.html");



// // フォーム 基本設定
// $.getScript("contact/js/formsetting.js");
//
// // 最後に、汎用スクリプトを読み込む
// $.getScript("common/js/fx-basic.js");

$('span.ajax-loader').hide();

$('.wpcf7-confirm').on('click', function(){
  // $("body").hide();

  setTimeout(function() {
    $("body").show();
    if($(".wpcf7c-conf").length) {
      console.log("confirm")
      $('body').addClass('is-confirm-page');
    }
  }, 800);
});
$('.wpcf7-back').on('click', function(){
  // $("body").hide();
  setTimeout(function() {
    $("body").show();
    $('body').removeClass('is-confirm-page');
  }, 300);
});
$('.wpcf7-submit').on('click', function(){
  location = "/contact/thanks/";
});
$('.wpcf7c-btn-back').on('click', function(){
  $("#input-label").show();
  $("#confirm-label").hide();
  $("input[name^=radio-]:not(:checked)").closest('span').show();
  $("small").show();
  $("[name=email_confirm]").show();
  $("section.form").attr('id', "INPUT");
});
