import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import isMobile from '../common/is-mobile.js';

var controller = new ScrollMagic.Controller();

if($(".top-hero").length) {

  if(!isMobile(768)){
    var scene_business_content1 = new ScrollMagic.Scene({
      triggerElement: ".js-animation-section1-1" ,
      triggerHook: 'onCenter',
      // duration: 800,
      offset :0
    })
    // .addIndicators()
    .addTo(controller);

    scene_business_content1.on('enter', ()=>{
      $('.js-animation-section1-1').addClass('is-run')

      var tl = new TimelineMax();

      tl
      // .to('.js-animation-section1-1 rect', 0.7, {attr:{ width:"100%"}, ease: Power2.easeIn, delay: 0.4})
      .to(".js-animation-section1-1 .top-mainContents-list", 0.6,{ y: "0", alpha: 1 })
      .staggerTo(".js-animation-section1-1 .top-mainContents-list__item-character" , 0.7 , {y: "0", alpha: 1, ease:Bounce.easeOut} , 0.2)
      // TweenMax.to('.js-animation-section1-1 rect', 0.7, {attr:{ width:"100%"}, ease: Power2.easeIn, delay: 0.4})
      //triggerElementを過ぎたとき
    });
    scene_business_content1.on('leave', ()=>{
      //triggerElementを抜けたとき
    });

    var scene_top_content2 = new ScrollMagic.Scene({
      triggerElement: ".js-animation-section2" ,
      triggerHook: 'onCenter',
      // duration: 800,
      offset :0
    })
    // .addIndicators()
    .addTo(controller);


    scene_top_content2.on('enter', ()=>{
      $('.js-animation-section2').addClass('is-run')

      var tl = new TimelineMax();

      tl
      .to('.js-animation-section2 rect', 0.7, {attr:{ width:"100%"}, ease: Power2.easeIn, delay: 0.3})
      .to(".js-animation-section2 .top-interview-header__inner", 0.7,{ y: "0", alpha: 1, delay: -0.1 })
      // .to('.js-animation-section2-1 rect', 0.7, {attr:{ width:"100%"}, ease: Power2.easeIn, delay: 0.4})
      // .to(".js-animation-section2-1", 0.6,{ y: "0", alpha: 1 })
      // TweenMax.to('.js-animation-section1-1 rect', 0.7, {attr:{ width:"100%"}, ease: Power2.easeIn, delay: 0.4})
      //triggerElementを過ぎたとき
    });
    scene_top_content2.on('leave', ()=>{
      //triggerElementを抜けたとき
    });

  }else {

    $('.top-mainContents-list__item').each( function(index) {
     $(this).addClass('number' + index);
     let that = $(this);

     var scene_business_content1 = new ScrollMagic.Scene({
       triggerElement: ".number" + index,
       triggerHook: 'onCenter',
       // duration: 100,
       offset: -200
     })
     // .addIndicators()
     .addTo(controller);

     // .setClassToggle("#aaaa", "is-current")

     scene_business_content1.on('enter', ()=>{
       var tl = new TimelineMax();
       tl
       .to($(that), 0.6,{ y: "0", alpha: 1 })
       .to($(that).find('.top-mainContents-list__item-character') , 0.7 , {y: "0", alpha: 1, ease:Bounce.easeOut} , 0.2)
     });

   })

   var scene_top_content2 = new ScrollMagic.Scene({
     triggerElement: ".js-animation-section2" ,
     triggerHook: 'onCenter',
     // duration: 800,
     offset :0
   })
   // .addIndicators()
   .addTo(controller);


   scene_top_content2.on('enter', ()=>{

     var tl = new TimelineMax();

     tl
     .to('.js-animation-section2 rect', 0.5, {attr:{ width:"100%"}, ease: Power2.easeIn})
     .set('.js-animation-section2', {className:"+=is-run"})
     .to(".js-animation-section2 .top-interview-header__inner", 0.5,{ y: "0", alpha: 1, delay: 0.6 })
     // .to('.js-animation-section2-1 rect', 0.7, {attr:{ width:"100%"}, ease: Power2.easeIn, delay: 0.4})
     // .to(".js-animation-section2-1", 0.6,{ y: "0", alpha: 1 })
     // TweenMax.to('.js-animation-section1-1 rect', 0.7, {attr:{ width:"100%"}, ease: Power2.easeIn, delay: 0.4})
     //triggerElementを過ぎたとき
   });
   scene_top_content2.on('leave', ()=>{
     //triggerElementを抜けたとき
   });

  }
}

var scene_business_content3 = new ScrollMagic.Scene({
  triggerElement: ".js-animation-section3" ,
  triggerHook: 'onCenter',
  // duration: 800,
  offset :-300
})
// .addIndicators()
.addTo(controller);


scene_business_content3.on('enter', ()=>{
  $('.js-animation-section3').addClass('is-run')

  var tl = new TimelineMax();

  tl
  // .to(".js-animation-section3 .c-scrollPageTop", 0.7,{ y: "0", alpha: 1 })
  .to(".js-animation-section3 .c-scrollPageTop" , 0.7 , {y: "0", alpha: 1, ease:Bounce.easeOut} , 0.2)
  // TweenMax.to('.js-animation-section1-1 rect', 0.7, {attr:{ width:"100%"}, ease: Power2.easeIn, delay: 0.4})
  //triggerElementを過ぎたとき
});
scene_business_content3.on('leave', ()=>{
  //triggerElementを抜けたとき
});
