import isMobile from '../common/is-mobile.js';

//スマホで画面の高さ取得・設定
$(window).on('resize', function(){
  if(isMobile(768)) {
    $('.top-hero').css({
      'height': $(window).height() - 50
    });
  } else {
    $('.top-hero').removeAttr('style');
  }
}).trigger('resize');


import YouTubePlayer from 'youtube-player';

let player;
// var $video = $('.js-youtube-player-content');

if($('.top-hero').length) {
  if(!isMobile(768)) {
    player = YouTubePlayer('js-youtube-video01', {
      width: '100%',
      height: '100%',
      videoId: '9YahmAf0Qxg',
      enablejsapi: 1,
      playerVars: {
        autoplay: 1,
        modestbranding: 1,
        enablejsapi: 1,
        showinfo: 0,
        controls: 0, //再生ボタンとか出さない
        showinfo: 0, //動画名とか出さない
        disablekb: 1, //ショートカットキー無効
        origin: "http://chronos-com.check-xserver.jp/",
        rel: 0 //関連動画出さない
      }
    });


    $('.js-youtube-player').css({
      'overflow': 'hidden',
      'position': 'absolute',
      'top': '0',
      'left': '0',
      'width': '100%',
      'height': '100%'
    });
    $('.js-youtube-player-content').css({
      'width': '100%',
      'height': '100%'
    });

    $('.js-youtube-player').each(function(i, v){
      var $this = $(this);

      $this.find('.js-youtube-player-content').css({
        'width': '100%',
        'height': '100%',
        'color': '#fff'
      })


      $(window).on('resize load', function(){
        // $video.removeAttr('style');
        var videoHeight = $this.find('.js-youtube-player-content').height();
        var videoWidth = $this.find('.js-youtube-player-content').width();
        // console.log($this.find('.js-youtube-player-content').width());

        if ($this.width() * 9/16 < $this.height()) {
          $this.find('.js-youtube-player-content').css({
            'width': $this.find('.js-youtube-player-content').height() * 16 / 9,
            'height': $this.find('.js-youtube-player-content').height()
          });
          videoWidth = $this.find('.js-youtube-player-content').width();
          videoHeight = $this.find('.js-youtube-player-content').height();
          $this.find('.js-youtube-player-content').css({
            'position' : 'absolute',
            'left': -(videoWidth - $this.width()) / 2
          });
          // var ratio = 16/9;
          // console.log($this.find('.js-youtube-player-content').width() * 9/16);
        } else {
          $this.find('.js-youtube-player-content').css({
            'position' : 'absolute',
            'width': $this.width(),
            'height': $this.width() * 9 / 16,
            'left': 0,
            'top': -(videoHeight - $this.height()) / 2
          });
        }
      }).trigger('resize');
    })


    player.on('stateChange', (event) => {
      event.target.mute();
      player.playVideo();
      if (event.data == YT.PlayerState.PLAYING) {
        event.target.playVideo();
        $(".top-hero-sub__movieCover").hide();
      }
      if (event.target.getPlayerState() == YT.PlayerState.ENDED) {
        player.playVideo();
        // $('#video-player').hide();
      }
    });
    player.playVideo();
  }

}
